import React from "react";
import { CopyrightWrap } from "./style";

const Copyright = () => {
    return(
        <CopyrightWrap>
            Hak cipta © PT. Sliva Technology Indonesia 2022
        </CopyrightWrap>
    )
}


export default Copyright


